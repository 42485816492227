import Navbar from "./Navbar";
import { React, useState } from "react";
import { Breadcrumb } from "antd";
import "../Styles/ClientDetails.css";
import avatar from "../Images/avatar.png";
import { useLocation } from "react-router-dom";
// import ClientSlideover from "./ClientSlideover";

export default function ClientDetails() {
  const [selectedSection, setSelectedSection] = useState("Reservation History");
  const [tabs, setTabs] = useState([
    { name: "Reservation History", current: true, value: 0 },
    // { name: "Favourites", current: false, value: 1 },
  ]);
  const location = useLocation();
  const client = location.state.client; 

  const handleNavItemClick = (itemName) => {
    setSelectedSection(itemName);

    const updatedTabs = tabs.map((item) => ({
      ...item,
      current: item.name === itemName,
    }));
    setTabs(updatedTabs);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [isSlideoverOpen, setIsSlideoverOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  const bookings = [
    {
      date: "29 May",
      location: "BarBaar",
      status: "Completed",
      code: "#89090",
    },
    {
      date: "22 May",
      location: "BarBaar",
      status: "Cancelled",
      code: "#89091",
    },
    {
      date: "22 May",
      location: "BarBaar",
      status: "Completed",
      code: "#89091",
    },
    {
      date: "22 May",
      location: "BarBaar",
      status: "Cancelled",
      code: "#89091",
    },
    {
      date: "22 May",
      location: "BarBaar",
      status: "Completed",
      code: "#89091",
    },
    {
      date: "22 May",
      location: "BarBaar",
      status: "Cancelled",
      code: "#89091",
    },
    {
      date: "22 May",
      location: "BarBaar",
      status: "Completed",
      code: "#89091",
    },
  ];

  return (
    <>
      <div>
        <Navbar />

        {/* Heading */}
        <div class="w-full pl-5 pr-5 pt-4 items-center justify-between inline-flex">
          <div class="text-neutral-800 text-2xl font-medium font-['Inter']">
            Clients Profile
          </div>
        </div>

        {/* tab bar  */}
        <div className="w-full pl-6 pr-5 pt-2 pb-2 items-center justify-between inline-flex">
          <Breadcrumb
            separator=">"
            items={[
              {
                title: (
                  <span
                    style={{
                      color: "#7C7C80",
                      fontFamily: "Inter",
                      fontSize: "0.75rem",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    Clients
                  </span>
                ),
              },
              {
                title: (
                  <span
                    style={{
                      color: "#4A6AFE",
                      fontFamily: "Inter",
                      fontSize: "0.75rem",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    Client Profile
                  </span>
                ),
                href: "",
              },
            ]}
          />
        </div>

        {/* Clients data */}

        <div
          className="clientData bg-lightBlue px-5 pb-10 pt-8"
          style={{ minHeight: "80vh" }}
        >
          <div className="leftDetail">
            <img src={avatar} alt="" />
            <h2>{client.name}</h2>
            <div className="insideLeftDetail">
              <h3>Email</h3>
              <input type="email" name="" id="" value={client.email} readOnly />
            </div>
            <div className="insideLeftDetail">
              <h3>Phone Number</h3>
              <input type="number" name="" id="" />
            </div>
            <div className="insideLeftDetail">
              <h3>Date of Birth</h3>
              <input type="number" name="" id="" />
            </div>
          </div>

          <div className="rightDetail">
            <div className="topRightDetail">
              <div>
                <h4>12</h4>
                <h5>All bookings</h5>
              </div>
              <div>
                <h4>10</h4>
                <h5>Completed</h5>
              </div>
              <div>
                <h4>2</h4>
                <h5>Cancelled</h5>
              </div>
            </div>

            <div className="bottomRightDetail">
              {/* tab bar  */}
              <nav className="px-5 flex space-x-8 pt-2" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    onClick={() => handleNavItemClick(tab.name)}
                    className={classNames(
                      tab.current
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                      "flex whitespace-nowrap border-b-2 py-3 px-1 text-sm font-medium"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                    style={{ alignItems: "center" }}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>

              {/* Booking List */}
              {bookings.map((booking, index) => (
                <div
                  key={index}
                  className="RightTable"
                  //   onClick={() => handleRightTableClick(booking)}
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <h3>{booking.date.split(" ")[0]}</h3>
                    <h4>{booking.date.split(" ")[1]}</h4>
                  </div>
                  <div>
                    <h3>{booking.location}</h3>
                    <h4>Occidental AI Jaddaf, Dubai</h4>
                  </div>

                  <div
                    className={`${
                      booking.status === "Completed"
                        ? "bg-checkOutBG"
                        : "bg-cancelledBG"
                    } w-32 rounded-3xl py-2 pl-4 pr-3 flex gap-2`}
                    style={{ alignItems: "center" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="6"
                      viewBox="0 0 5 6"
                      fill="none"
                    >
                      <circle
                        cx="2.5"
                        cy="3"
                        r="2.5"
                        fill={
                          booking.status === "Completed" ? "#077E8C" : "#F44336"
                        }
                      />
                    </svg>
                    <span
                      className={`${
                        booking.status === "Completed"
                          ? "text-checkOutText"
                          : "text-cancelledText"
                      }`}
                    >
                      {booking.status}
                    </span>
                  </div>
                  <div className="resCode">{booking.code}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Slideover component */}
          {/* <ClientSlideover
            isOpen={isSlideoverOpen}
            setIsOpen={setIsSlideoverOpen}
            booking={selectedBooking}
          /> */}
        </div>
      </div>
    </>
  );
}
