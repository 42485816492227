import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Components/Navbar";
import "../Styles/Settings.css";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import NoBookings from "../Images/no-notifications-hfkUZp4KLR.png";
import "../Styles/Bookings.css";
import { handleApiError } from "../Components/apiErrorHandling";
import BasicRangeShortcuts from "../Pages/Demo";
import BookingTable from "./BookingTable";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useEstateCode } from "../Components/EstateCodeContext";
import SlideOverThree from "../Components/SlideOverThree";

const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

const Bookings = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(true);
  const { estateCode } = useEstateCode();
  const [tabs, setTabs] = useState([
    { name: "Pending", count: 0, current: true, value: 0 },
    { name: "Upcoming", count: 0, current: false, value: 5 },
    { name: "Ongoing", count: 0, current: false, value: 3 },
    { name: "Completed", count: 0, current: false, value: 4 },
    { name: "Cancelled", count: 0, current: false, value: 2 },
    { name: "All", count: 0, current: false, value: "All" },
  ]);

  const [allbookings, setAllBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);

  const [selectedSection, setSelectedSection] = useState("Pending");
  const [searchQuery, setSearchQuery] = useState("");
  const formatDateString = (date) => {
    return date ? new Date(date).toISOString().split("T")[0] : "";
  };
  const currentDate = new Date();
  const defaultStartDate = new Date(currentDate);
  const defaultEndDate = new Date(currentDate);
  defaultEndDate.setDate(currentDate.getDate() + 7);

  const [dateRange, setDateRange] = useState([
    defaultStartDate,
    defaultEndDate,
  ]);

  const [dateRangeValue, setDateRangeValue] = useState(null);

  const [startDate, setStartDate] = useState(
    formatDateString(defaultStartDate)
  );
  const [endDate, setEndDate] = useState(formatDateString(defaultEndDate));

  const handleChange = (value) => {
    setDateRangeValue(value || [defaultStartDate, defaultEndDate]);
  };

  // DATE RANGE SETTER
  useEffect(() => {
    if (!dateRangeValue) {
      setDateRange([defaultStartDate, defaultEndDate]);
    } else {
      setDateRange(dateRangeValue);
    }
  }, [dateRangeValue]);

  useEffect(() => {
    setStartDate(formatDateString(dateRange[0]));
    setEndDate(formatDateString(dateRange[1]));
  }, [dateRange, selectedSection]);

  const fetchBookings = async () => {
    try {
      let choose;
      if (selectedSection === "All") choose = "All";
      if (selectedSection === "Pending") choose = 0;
      if (selectedSection === "Upcoming") choose = 5;
      if (selectedSection === "Cancelled") choose = 2;
      if (selectedSection === "Completed") choose = 3;
      if (selectedSection === "Ongoing") choose = 4;
  
      const url = getApiUrl(
        `/api/v2/merchant/bookings/viewDateFilteredBooking/${estateCode}/d/${choose}?startDate=${startDate}&endDate=${endDate}`
      );
      const response = await axios.get(url, {
        withCredentials: true,
      });
  
      console.log("Full response:", response);
  
      if (response.status === 200) {
        const rawData = response.data.data;

        const bookingsArray = Object.values(rawData).filter(
          (item) => typeof item === "object" && item.reservation_code
        ); 
        setAllBookings(bookingsArray.reverse()); 
      } else {
        console.warn(`Unexpected status code: ${response.status}`);
        setAllBookings([]);
      }
    } catch (error) {
      handleApiError(error);
    }
  };
  
  
  useEffect(() => {
    fetchBookings();
  }, [startDate, endDate, selectedSection, estateCode]);

  // SEARCH USE EFFECT
  useEffect(() => {
    const searchQueryLowerCase = searchQuery.trim().toLowerCase();

    if (allbookings && allbookings.length > 0) {
      const filtered = allbookings.filter((booking) => {
        const fullName =
          `${booking?.bookerFirstName} ${booking?.bookerLastName}`.toLowerCase();
        return (
          booking?.reservation_code
            ?.toLowerCase()
            .includes(searchQueryLowerCase) ||
          fullName.includes(searchQueryLowerCase)
        );
      });
      setCurrentPage(1);
      setFilteredBookings(filtered);
    } else {
      setFilteredBookings([]);
    }
  }, [searchQuery, allbookings]);

  const handleNavItemClick = (itemName) => {
    setSelectedSection(itemName);
    setCurrentPage(1);
    setOpen(false);
    setSelectedStatus(null);
    setSearchQuery("");
    const updatedTabs = tabs.map((item) => ({
      ...item,
      current: item.name === itemName,
    }));
    setTabs(updatedTabs);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const fetchBookingsCount = async () => {
    try {
      const url = getApiUrl(
        `/api/v2/merchant/bookings/getBookingCount/${estateCode}?value=All&startDate=${startDate}&endDate=${endDate}`
      );
      const response = await axios.get(url, {
        withCredentials: true,
      });
      switch (response.status) {
        case 200:
          const bookingCount = response.data.data[0];
          const updatedTabs = tabs.map((tab) => {
            switch (tab.name) {
              case "Pending":
                return { ...tab, count: bookingCount.pending_count || 0 };
              case "Upcoming":
                return { ...tab, count: bookingCount.accepted_count || 0 };
              case "Cancelled":
                return { ...tab, count: bookingCount.cancelled_count || 0 };
              case "All":
                return { ...tab, count: bookingCount.count_all || 0 };
              case "Completed":
                return { ...tab, count: bookingCount.checkedout_count || 0 };
              case "Ongoing":
                return { ...tab, count: bookingCount.checkedin_count || 0 };
              default:
                return tab;
            }
          });
          setTabs(updatedTabs);
          break;
        case 204:
          console.warn("No content available");
          break;
        default:
          console.warn(`Unexpected status code: ${response.status}`);
          break;
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    fetchBookingsCount();
  }, [startDate, endDate, estateCode]);

  useEffect(() => {
    if (selectedSection === "Pending") {
      const cancelReservation = async () => {
        try {
          const url = getApiUrl(
            "/api/v1/merchant/bookings/changePendingStatus"
          );
          await axios.get(url, {
            withCredentials: true,
          });
        } catch (error) {
          console.error(
            "Error occurred while making the cancel request:",
            error
          );
        }
      };

      cancelReservation();
    }
  }, [selectedSection]);

  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [displayedStatus, setDisplayedStatus] = useState("Status");
  const statusOptions = [
    { value: 5, label: "Upcoming" },
    { value: 0, label: "Pending" },
    { value: 2, label: "Cancelled" },
    { value: 3, label: "Completed" },
    { value: 4, label: "Ongoing" },
  ];

  const handleSelectedStatus = (status) => {
    if (status === null) {
      setSelectedStatus(null);
      setDisplayedStatus("Status");
      setIsStatusOpen(false);
    } else {
      setSelectedStatus(status);
      setDisplayedStatus(
        statusOptions.find((option) => option.value === status).label
      );
      setIsStatusOpen(false);
    }
  };

  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [displayedCategory, setDisplayedCategory] = useState("Package");
  const categoryOptions = [
    { value: "Beverage Packages", label: "Beverage Packages" },
    { value: "Brunch Packages", label: "Brunch Packages" },
    { value: "Champagne / Prosecco", label: "Champagne / Prosecco" },
    { value: "Set Menus", label: "Set Menus" },
    { value: "Greg Coulson Band", label: "Greg Coulson Band" },
  ];

  const handleSelectedCategory = (category) => {
    if (category === null) {
      setSelectedCategory(null);
      setDisplayedCategory("Category");
      setIsCategoryOpen(false);
    } else {
      setSelectedCategory(category);
      setDisplayedCategory(
        categoryOptions.find((option) => option.value === category).label
      );
      setIsCategoryOpen(false);
    }
  };

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [displayedPackage, setDisplayedPackage] = useState("Package");
  const [isPackageOpen, setIsPackageOpen] = useState(false);
  const packageOptions = [
    { value: "Regular", label: "Regular" },
    { value: "Premium", label: "Premium" },
    { value: "Exclusive", label: "Exclusive" },
    { value: "Ultra Exclusive", label: "Ultra Exclusive" },
  ];

  const handleSelectedPackage = (packageValue) => {
    if (packageValue === null) {
      setSelectedPackage(null);
      setDisplayedPackage("Package");
      setIsPackageOpen(false);
    } else {
      setSelectedPackage(packageValue);
      setDisplayedPackage(
        packageOptions.find((option) => option.value === packageValue).label
      );
      setIsPackageOpen(false);
    }
  };

  useEffect(() => {
    const searchQueryLowerCase = searchQuery.trim().toLowerCase();

    let filteredBookings = allbookings;

    if (selectedCategory) {
      filteredBookings = filteredBookings.filter(
        (booking) => booking.bookingPackageCategory === selectedCategory
      );
    }

    if (selectedPackage) {
      filteredBookings = filteredBookings.filter(
        (booking) => booking.bookingGrouplePackageCategory === selectedPackage
      );
    }

    if (selectedStatus !== null) {
      filteredBookings = filteredBookings.filter(
        (booking) => booking.record_status === selectedStatus
      );
    }

    filteredBookings = filteredBookings.filter((booking) => {
      const fullName =
        `${booking?.bookerFirstName} ${booking?.bookerLastName}`.toLowerCase();
      return (
        booking?.reservation_code
          ?.toLowerCase()
          .includes(searchQueryLowerCase) ||
        fullName.includes(searchQueryLowerCase)
      );
    });

    setCurrentPage(1);
    setFilteredBookings(filteredBookings);
  }, [
    searchQuery,
    allbookings,
    selectedCategory,
    selectedStatus,
    selectedPackage,
  ]);

  useEffect(() => {
    const storedPackage = localStorage.getItem("selectedPackage");
    const storedCategory = localStorage.getItem("selectedCategory");
    const storedSearchQuery = localStorage.getItem("searchQuery");
    // const storedTabs = localStorage.getItem("tabs");
    const storedDateRange = localStorage.getItem("dateRange");
    // const storedSelectedSection = localStorage.getItem("selectedSection");

    if (storedPackage) setSelectedPackage(JSON.parse(storedPackage));
    if (storedCategory) setSelectedCategory(JSON.parse(storedCategory));
    if (storedSearchQuery) setSearchQuery(storedSearchQuery);
    // if (storedTabs) setTabs(tabs);
    // if (storedSelectedSection) {setSelectedSection(storedSelectedSection);}

    setDateRange([defaultStartDate, defaultEndDate]);
    setDateRangeValue([defaultStartDate, defaultEndDate]);
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedPackage", JSON.stringify(selectedPackage));
    localStorage.setItem("selectedCategory", JSON.stringify(selectedCategory));
    // localStorage.setItem('selectedSection', selectedSection);
    // localStorage.setItem("tabs", JSON.stringify(tabs));
    localStorage.setItem("searchQuery", searchQuery);
  }, [selectedPackage, selectedCategory, searchQuery, tabs, selectedSection]);

  const categoryDropdownRef2 = useRef(null);
  const packageDropdownRef2 = useRef(null);
  const statusDropdownRef2 = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  const handleClickOutside2 = (event) => {
    if (
      categoryDropdownRef2.current &&
      !categoryDropdownRef2.current.contains(event.target)
    ) {
      setIsCategoryOpen(false);
    }
    if (
      packageDropdownRef2.current &&
      !packageDropdownRef2.current.contains(event.target)
    ) {
      setIsPackageOpen(false);
    }
    if (
      statusDropdownRef2.current &&
      !statusDropdownRef2.current.contains(event.target)
    ) {
      setIsStatusOpen(false);
    }
  };

  const [isAddReservationOpen, setIsAddReservationOpen] = useState(false);
  const handleAddResClick = () => {
    setIsAddReservationOpen(!isAddReservationOpen);
  };
  return (
    <>
      <div>
        <Navbar fetchBookings={fetchBookings} />
        {/* {/ Add reservation button  /} */}
        <div class="w-full pl-5 pr-5 pt-4 pb-2 items-center justify-between inline-flex">
          <div class="text-neutral-800 text-2xl font-medium font-['Inter']">
            Bookings
          </div>

          {/* <div
            class="bg-groupleBlue rounded-md cursor-pointer"
            onClick={handleAddResClick}
          >
            <div class="p-3 rounded-md border border-groupleBlue flex">
              <div class="text-white text-sm font-medium font-['Inter']">
                Add Reservation
              </div>
            </div>
          </div> */}
        </div>

        <nav className="px-5 flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              onClick={() => handleNavItemClick(tab.name)}
              className={classNames(
                tab.current
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                "flex whitespace-nowrap border-b-2 py-3 px-1 text-sm font-medium"
              )}
              aria-current={tab.current ? "page" : undefined}
              style={{ alignItems: "center" }}
            >
              {tab.name}
              <span
                className={classNames(
                  tab.current
                    ? "bg-groupleBlue text-white"
                    : "bg-lightBlue text-groupleBlue",
                  "ml-3 hidden rounded-md py-1 px-2 text-xs font-medium md:inline-block"
                )}
              >
                {tab.count}
              </span>
            </a>
          ))}
        </nav>

        <div className="bg-lightBlue py-6 px-5 flex justify-between">
          <div className="flex gap-2 w-3/4">
            <div style={{ width: "40%" }}>
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative text-gray-400 focus-within:text-gray-600">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search"
                  className="block w-full rounded-md border border-gray-300 py-2.5 pl-10 pr-3 text-gray-900 sm:text-sm sm:leading-6 focus:border-none focus:color-white"
                  placeholder="Search by booking ID, customer name"
                  type="search"
                  name="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div
              className="sort-wrapper-main"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsPackageOpen(false);
                setIsStatusOpen(false);
              }}
              ref={categoryDropdownRef2}
            >
              <div className="custom-dropdown">
                <div
                  className={`dropdown-header ${
                    selectedCategory ? "active" : ""
                  }`}
                  onClick={() => setIsCategoryOpen(!isCategoryOpen)}
                >
                  {selectedCategory ? (
                    <div className="Time-placeHolder">
                      <div>
                        <div className="Time-Value">Category</div>
                      </div>
                      <div>
                        <ChevronDownIcon
                          className={`chevron-icon ${
                            isCategoryOpen ? "up" : "down"
                          }`}
                          style={{
                            transform: isCategoryOpen
                              ? "rotate(180deg)"
                              : "none",
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="Time-placeHolder">
                      <div className="Time-placeholder-child text-gray-500">
                        Category
                      </div>
                      <div>
                        <ChevronDownIcon
                          className={`chevron-icon ${
                            isCategoryOpen ? "up" : "down"
                          }`}
                          style={{
                            transform: isCategoryOpen
                              ? "rotate(180deg)"
                              : "none",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {isCategoryOpen && (
                  <div className="dropdown-packageOptions-two">
                    {categoryOptions.map((option) => (
                      <div
                        key={option.value}
                        style={{ borderRadius: "0.25rem" }}
                        className={`dropdown-option flex justify-between hover:bg-customLilac hover:text-groupleBlue ${
                          selectedCategory === option.value ? "selected" : ""
                        }`}
                        onClick={() => handleSelectedCategory(option.value)}
                      >
                        {option.label}
                        {selectedCategory === option.value && (
                          <button
                            className="clear-filter-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelectedCategory(null);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18 18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div
              className="sort-wrapper-main"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsCategoryOpen(false);
                setIsStatusOpen(false);
              }}
              ref={packageDropdownRef2}
            >
              <div
                className={`dropdown-header ${selectedPackage ? "active" : ""}`}
                onClick={() => setIsPackageOpen(!isPackageOpen)}
              >
                {selectedPackage ? (
                  <div className="Time-placeHolder">
                    <div>
                      <div className="Time-Value">Package</div>
                    </div>
                    <div>
                      <ChevronDownIcon
                        className={`chevron-icon ${
                          isPackageOpen ? "up" : "down"
                        }`}
                        style={{
                          transform: isPackageOpen ? "rotate(180deg)" : "none",
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="Time-placeHolder">
                    <div className="Time-placeholder-child text-gray-500">
                      Package
                    </div>
                    <div>
                      <ChevronDownIcon
                        className={`chevron-icon ${
                          isPackageOpen ? "up" : "down"
                        }`}
                        style={{
                          transform: isPackageOpen ? "rotate(180deg)" : "none",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {isPackageOpen && (
                <div className="dropdown-packageOptions-two">
                  {packageOptions.map((option) => (
                    <div
                      key={option.value}
                      style={{ borderRadius: "0.25rem" }}
                      className={`dropdown-option flex justify-between hover:bg-customLilac hover:text-groupleBlue ${
                        selectedPackage === option.value ? "selected" : ""
                      }`}
                      onClick={() => handleSelectedPackage(option.value)}
                    >
                      {option.label}
                      {selectedPackage === option.value && (
                        <button
                          className="clear-filter-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectedPackage(null);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18 18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {selectedSection === "All" && (
              <div
                className="sort-wrapper-main"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsPackageOpen(false);
                  setIsCategoryOpen(false);
                }}
                ref={statusDropdownRef2}
              >
                <div
                  className={`dropdown-header ${
                    selectedStatus ? "active" : ""
                  }`}
                  onClick={() => setIsStatusOpen(!isStatusOpen)}
                >
                  {selectedStatus ? (
                    <div className="Time-placeHolder">
                      <div>
                        <div className="Time-Value">Status</div>
                      </div>
                      <div>
                        <ChevronDownIcon
                          className={`chevron-icon ${
                            isStatusOpen ? "up" : "down"
                          }`}
                          style={{
                            transform: isStatusOpen ? "rotate(180deg)" : "none",
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="Time-placeHolder">
                      <div className="Time-placeholder-child text-gray-500">
                        Status
                      </div>
                      <div>
                        <ChevronDownIcon
                          className={`chevron-icon ${
                            isStatusOpen ? "up" : "down"
                          }`}
                          style={{
                            transform: isStatusOpen ? "rotate(180deg)" : "none",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {isStatusOpen && (
                  <div className="dropdown-packageOptions-two">
                    {statusOptions.map((option) => (
                      <div
                        key={option.value}
                        style={{ borderRadius: "0.25rem" }}
                        className={`dropdown-option flex justify-between hover:bg-customLilac hover:text-groupleBlue ${
                          selectedStatus === option.value ? "selected" : ""
                        }`}
                        onClick={() => handleSelectedStatus(option.value)}
                      >
                        {option.label}
                        {selectedStatus === option.value && (
                          <button
                            className="clear-filter-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelectedStatus(null);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18 18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
          <BasicRangeShortcuts
            dateRangeValue={dateRangeValue}
            onChange={handleChange}
            selectedSection={selectedSection}
          />
        </div>

        <div className="bg-lightBlue px-5 pb-10" style={{ minHeight: "75vh" }}>
          {filteredBookings && filteredBookings.length > 0 ? (
            <BookingTable
              bookings={filteredBookings}
              headers={[
                "# Booking ID",
                "Booking date",
                "Time slot",
                "Customer name",
                "Group size",
                "Category",
                "Package",
                "Total amount",
                "Status",
              ]}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setOpen={setOpen}
              open={open}
              fetchBookings={fetchBookings}
              fetchBookingsCount={fetchBookingsCount}
            />
          ) : (
            <div className="noBookingWrapperTwo">
              <img src={NoBookings} alt="No Bookings" />
              <h2>No Booking</h2>
            </div>
          )}
        </div>
      </div>

      {/* {/ SlideOver /} */}
      {isAddReservationOpen && (
        <SlideOverThree
          isAddReservationOpen={isAddReservationOpen}
          fetchBookings={fetchBookings}
          fetchBookingsCount={fetchBookingsCount}
          onClose={() => setIsAddReservationOpen(false)}
        />
      )}
    </>
  );
};

export default Bookings;